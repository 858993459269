<template>
  <div class="system">
    <div class="return" v-if="clienId" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>
    <div class="top">
      <div class="title">
        <i class="el-icon-collection-tag" />基本资料
      </div>
	  <div style="text-align: right;">
      <el-button  v-if="detail.status != 3 && detail.is_cancel == 1" type="danger" size="mini" class="mb10" plain @click="hint('cancel','订单原路退款')">订单退款</el-button>
      <el-button  v-if="detail.status != 3" type="danger" size="mini" class="mb10" plain @click="openDialog('修改订单')">修改订单</el-button>
      <el-button  v-if="detail.status != 3" type="danger" size="mini" class="mb10" plain @click="hint('del','作废此订单')">作废订单</el-button>
	  </div>

    <!-- 弹框 -->
    <el-dialog title="分配业绩" :visible.sync="dialogVisible">
      <el-form :model="form">
        <el-form-item label="分配给**店" :label-width="formLabelWidth">
          <el-input-number v-model="form.turn" :precision="2" :step="0.1" :max="99999999"></el-input-number>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTurn()">确 定</el-button>
      </div>
    </el-dialog>

    </div>
    <el-descriptions class="margin-top" :column="4" border>
      <el-descriptions-item>
        <template slot="label">销售部门</template>
        {{detail.shop_name}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">客户姓名</template>
        {{detail.consignee}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">订单单号</template>
        {{detail.refund_order_no}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">类型</template>
        <span v-if="detail.type==1">退款单</span>
        <span v-if="detail.type==2">退货单</span>
        <span v-if="detail.type==3">收款单</span>
      </el-descriptions-item>
      <el-descriptions-item v-if="noKing == 1">
        <template slot="label">开单员姓名</template>
        {{detail.sale_name}}
      </el-descriptions-item>
      <el-descriptions-item  v-else>
        <template slot="label">录单员姓名</template>
        {{detail.operator_name}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">业务类型</template>
        {{detail.business_type}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">业务类型二级分类</template>
        {{detail.business_two_type_text}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">订单类型</template>
        {{detail.order_type}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">订单分类</template>
        {{detail.order_class_text}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==2">
        <template slot="label">销售组织</template>
        {{detail.sales_name}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type!=2">
        <template slot="label">结算组织</template>
        {{detail.sales_name}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==2">
        <template slot="label">发货组织</template>
        {{detail.settlement_name}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type!=2">
        <template slot="label">收/退款组织</template>
        {{detail.settlement_name}}
      </el-descriptions-item>
      <el-descriptions-item>
          <template slot="label">金蝶单号</template>
          {{detail.king_num?detail.king_num:'暂无'}}
        </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">订单状态</template>
        <span v-if="detail.status == 0">待支付</span>
        <span v-if="detail.status == 1">已支付</span>
        <span v-if="detail.status == 2">已下推</span>
        <span v-if="detail.status == 3">已作废</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" v-if="detail.type==1">退款总计</template>
        <template slot="label" v-if="detail.type==2">退货总计</template>
        <template slot="label" v-if="detail.type==3">收款总计</template>
        {{detail.total_amount}}
      </el-descriptions-item>
      <el-descriptions-item >
        <template slot="label">赠金</template>
        {{detail.gift_amount}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">本金</template>
        {{detail.pay_amount}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">店转</template>
        {{detail.shop_transfer_amount}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.is_treatment > 0">
        <template slot="label">转诊类型</template>
        {{detail.is_treatment == 1?'疗程':'非疗程'}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.referral_shop_name">
        <template slot="label">转诊门店</template>
        {{detail.referral_shop_name?detail.referral_shop_name:"无"}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">成交时间</template>
        {{detail.deal_time?detail.deal_time:'暂无'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">创建时间</template>
        {{detail.create_time?detail.create_time:'暂无'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">金蝶推送状态</template>
        <span v-if="detail.king_status == 0">默认</span>
        <span v-if="detail.king_status == 1">成功</span>
        <span v-if="detail.king_status == 2">失败</span>
        <span v-if="detail.king_status == 5">登陆失败</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">销售政策编码</template>
        {{detail.sales_policy?detail.sales_policy:'暂无'}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==1">
        <template slot="label">退款类型</template>
        <span v-if="detail.refund_type == '101.01'">退款店转</span>
        <span v-if="detail.refund_type == '101.02'">退款</span>
        <span v-if="detail.refund_type == '101.03'">退款调整</span>
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==1" >
        <template slot="label">退款审批类型</template>
        <span v-if="detail.combo == 0">暂无</span>
        <span v-if="detail.combo == 1">项目退款</span>
        <span v-if="detail.combo == 2">疤痕退款</span>
        <span v-if="detail.combo == 3">第三方分成</span>
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==1">
      </el-descriptions-item>
      <el-descriptions-item :span="2">
        <template slot="label">状态描述</template>
        {{detail.status_msg}}
      </el-descriptions-item>

	  <!-- <el-descriptions-item>
	    <template slot="label">是否体验</template>
	    {{detail.is_experience_text}}
	  </el-descriptions-item> -->
	  <!-- <el-descriptions-item>
	    <template slot="label">体验人</template>
	    {{detail.experience_name}}
	  </el-descriptions-item> -->

	  <!-- <el-descriptions-item>
	    <template slot="label">是否外联</template>
	    {{detail.is_outreach_text}}
	  </el-descriptions-item> -->
	  <!-- <el-descriptions-item>
	    <template slot="label">外联人</template>
	    {{detail.outreahc_name}}
	  </el-descriptions-item> -->

	  <el-descriptions-item>
	    <template slot="label">是否转介绍</template>
	    {{detail.is_introduction_text}}
	  </el-descriptions-item>
	  <el-descriptions-item>
	    <template slot="label">转介绍人</template>
	    {{detail.introduction_name}}
	  </el-descriptions-item>

    <el-descriptions-item>
      <template slot="label">选择商户</template>
      {{detail.shanghu_type}}
    </el-descriptions-item>
    <el-descriptions-item>
      <template slot="label">收款方式</template>
      {{detail.refund_type_plus}}
    </el-descriptions-item>

    <el-descriptions-item v-if="isShowRefunder">
    <template slot="label">退款人</template>
      {{detail.refunder}}
    </el-descriptions-item>

    <el-descriptions-item v-if="isShowRefundAccount">
      <template slot="label">退款卡号</template>
      {{detail.refund_account}}
    </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">订单描述</template>
        {{detail.description?detail.description:'暂无'}}
      </el-descriptions-item>

	  <el-descriptions-item v-if="detail.type==2" >
	    <template slot="label">收入类型</template>
	    <span v-if="detail.income_type == 1">服务（各类疗程卡项及疗程卡项赠送产品）</span>
	    <span v-if="detail.income_type == 2">产品销售（单独付费购买产品）</span>
	    <span v-if="detail.income_type == 3">医美收入</span>
	  </el-descriptions-item>

    </el-descriptions>
    <div class="top">
      <div class="title mt20">
        <i class="el-icon-collection-tag" />
        <span v-if="type == 1">退款单资料</span>
        <span v-if="type == 2 ">退货商品资料</span>
        <span v-if="type == 3 ">收款单资料1</span>
      </div>
    </div>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          v-if="type == 1 || type == 3"
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >


          <ElTableColumn label="业务类型"  prop="data_business_type_text" />
          <ElTableColumn label="业务二级类型" prop="data_business_two_type_text" />
          <ElTableColumn label="方式">
            <template slot-scope="{ row }">
              <span v-if="row.refund_mode == '01'">现金</span>
              <span v-if="row.refund_mode == '02'">刷卡/微信/支付宝</span>
              <span v-if="row.refund_mode == '03'">基本户收款</span>
              <span v-if="row.refund_mode == '04'">分期</span>
              <span v-if="row.refund_mode == '05'">平台收款</span>
              <span v-if="row.refund_mode == '06'">店转</span>
              <span v-if="row.refund_mode == '07'">对公银行账户付款</span>
              <span v-if="row.refund_mode == '08'">个人银行账户付款</span>
              <span v-if="row.refund_mode == '12'">内部结算</span>
            </template>
          </ElTableColumn>
          <ElTableColumn label="本金"  >
            <template slot-scope="{ row }">
              <span>{{row.pay_price}}</span>
            </template>
          </ElTableColumn>

          <ElTableColumn label="赠金" >
            <template slot-scope="{ row }">
                <span>{{row.gift_price}}</span>
              </template>
          </ElTableColumn>
          <ElTableColumn label="店转金额"  >
            <template slot-scope="{ row }">
              <span>{{row.shop_transfer_price}}</span>
            </template>
          </ElTableColumn>

          <ElTableColumn label="银行号" prop="bank_number" />
          <ElTableColumn label="销售政策编码" prop="data_sales_policy" />
          <ElTableColumn label="描述" prop="descs" />
        </el-table>
        <el-table
          v-else
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="商品名称" prop="goods_name" />
          <ElTableColumn label="商品名称-别名" prop="goods_alias_name" />
          <ElTableColumn label="商品含税单价" prop="pay_price" />
          <ElTableColumn label="商品规格金蝶编码" prop="kingdee_code" />
          <ElTableColumn label="是否赠品">
            <template slot-scope="{ row }">
              <span v-if="row.is_gift == '0'">否</span>
              <span v-if="row.is_gift == '1'">是</span>
            </template>
          </ElTableColumn>
          <ElTableColumn label="仓库编码" prop="house_code" />
          <ElTableColumn label="退款业务类型" prop="data_business_type_text" />
          <ElTableColumn label="是否退预存货" prop="is_refund_prestock" />
          <ElTableColumn label="退货数量" prop="return_num" />
          <ElTableColumn label="退货款本金" prop="refund_principal_price" />
          <ElTableColumn label="退款款赠金" prop="gift_price" />
          <ElTableColumn label="退货款店转" prop="shop_transfer_price" />
          <ElTableColumn label="商品退款描述" prop="descs" />
        </el-table>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { refundDetail, orderRefundDetail,delRefundOrder,addTurnRefund } from "@/api/order";
export default {
  name: "system",
  data() {
    return {
      system: {},
      remark: "",
      detail: [],
      list: [],
      type: "",
      clienId: "",
      types: "",
      dialogVisible: false,
      form: {},
      formLabelWidth:'120px',
      noKing:'',
      isShowProjectType:false,
      isShowRefundAccount:false,
      isShowRefunder:false,
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.types = this.$route.query.types;
    this.clienId = this.$route.query.clienId;
    this.noKing = this.$route.query.noKing;
    this.getDetail();

  },
  methods: {
    // 获取详情
    getDetail() {
      refundDetail({
        order_id: this.$route.query.id,
        type: this.$route.query.type
      }).then(res => {
        this.detail = res;
        if(this.detail.type==2){
          this.isShowProjectType = true;
        }
        if(this.detail.type==1){
          this.isShowRefundAccount = true;
          this.isShowRefunder = true;
        }
      });
      orderRefundDetail({
        order_id: this.$route.query.id,
        type: this.$route.query.type
      }).then(res => {
        console.log(res.goodsList);
        this.list = res.goodsList;
      });
    },
    // 提示
    hint(type,text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if(type == 'del'){
            this.delOrder();
          }
          if(type == 'cancel'){
            this.cancelOrder();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 撤销订单
    cancelOrder(){
      console.log('撤销订单');
      delRefundOrder({ order_id: this.$route.query.id,status:3,is_cancel:1}).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          if(this.clienId){
            this.back()
          }else{
            this.$router.go(-1)
          }
      });
    },
    // 作废订单
    delOrder(){
      delRefundOrder({ order_id: this.$route.query.id,status:3}).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          if(this.clienId){
            this.back()
          }else{
            this.$router.go(-1)
          }
      });
    },
	// 修改订单信息
	openDialog(type, row) {

	 const query = { detail: this.detail,type: this.type  };
	 switch (type) {
	   case "修改订单":
	     this.$router.push({ name: "修改订单", query });
	     break;
	  }
	},

    // 返回
    back() {
      const query = {
        id: this.$route.query.clienId,
        customer_name: this.$route.query.detail.customer_name,
        detail:this.$route.query.detail,
        activeName: this.$route.query.types
      };
      this.$router.push({ name: "客户详情", query });
    },

    submitTurn()
    {
      console.log(this.form.turn);
      console.log(this.detail.refund_order_no);

      // const query = {
      //   refundID : this.detail.refund_order_no,
      //   turnMoney : this.form.turn,
      // }

      this.$confirm(`分配业绩是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        addTurnRefund({
          refundID: this.detail.refund_order_no,
          turnMoney : this.form.turn,
        }).then(res => {
          console.log(res);
        });
      }).catch(() => {
        console.log('444');
      });

    },

  }
};
</script>

<style lang='scss' scoped>
.return {
  position: fixed;
  right: 20px;
  top: 71px;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
.top {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 34px;
    color: #666;
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background: #f9fafc;
}

</style>
